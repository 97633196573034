.Toastify__toast {
    min-height: 45px;
    padding: 8px;
}
.toast-notify .Toastify__toast{
	padding: 10px 15px;
}
.toast-notify .bg-success {
    background-color: #fff701!important;
    color: #23292e;
}
.msg-success .Toastify__close-button {
	color: #23292e;
}
.full-section{
	min-height: 320px;
}
.alluploaded-images .List{
	position: relative;
}
.alluploaded-images .dragged{
	background: #e2e2e2;
}
.alluploaded-images .draggable{
	width: 100%;
}
.alluploaded-images .draggable:not(:first-child){
	border-top: 1px #dedede solid;
}
.date-container {
	display: block;
}
.date-container .react-datepicker-wrapper{
	width: 100%;
}
body .body-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .1);
    z-index: 99;
    cursor: progress;
}
body .spinner-border {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
}
.alert {
    padding: 7px 12px;
    border-radius: 0px;
}
.alert-dismissible .close {
    padding: .40rem .7rem;
}
.uplod-container .upload-img .rounded-0 {
    border-radius: 0!important;
}
.FileUploadImageCss>div:nth-child(2) div:nth-child(2){max-width: 900px !important;height: 600px;
    overflow-y: scroll;
}
.FileUploadImageCss>div:nth-child(2) div:nth-child(2)::-webkit-scrollbar-thumb{border-radius:10px;-webkit-border-radius:10px;-moz-border-radius:10px;background-color:#cccccc;display:none !important;}
.FileUploadImageCss>div:nth-child(2) div:nth-child(2)::-webkit-scrollbar{width:4px;height:30px;background-color:#ffffff;display:none !important;}
.FileUploadImageCss>div:nth-child(2) div:nth-child(2)::-webkit-scrollbar-track{border-radius:10px;-webkit-border-radius:10px;-moz-border-radius:10px;background-color:#ffffff;display:none !important;}
.uplod-container{z-index:9999999; }